.gallery-swiper{
    background-color: #000000;
    .slide-item{
        height: 100%;
    }
    .swiper-slide-next{
        .slide-item{
            background: linear-gradient(90deg, rgba(0,0,0,0.7) 0%, #000000 100%);
        }
    }
    .swiper-slide-prev{
        .slide-item{
            background: linear-gradient(270deg, rgba(0,0,0,0.7) 0%, #000000 100%);
        }
    }
}