.adventure-item{
    font-family: Heebo;	
    .nearby-sub-title, .nearby-guide{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .nearby-sub-title{
        color: #000000;	
        font-weight: bold;	
        margin-top: 0.25rem;
        max-width: 245px;
    }
    .nearby-guide{	
        color: #7F8C8D;	
        font-size: 12px;
        margin-right: 1vh;
        max-width: 50%;
        line-height: 20px;
        margin-bottom: 0;
    }
    .inline-nearby{
        display: flex;
        max-width: 245px;
    }
    .rating{
        display: inline-flex;
        line-height: 15px;
    }
    .adventure-item-content{
        height: 146px;
        width: 270px;
        position: relative;
        display:inline-block;
        .price-rectangle{
            position: absolute;
            bottom: 0;
        }
    }
    .adventure-img{
        border-radius: 4px;
        height: 100%;
        display: flex;
        display: block;
        object-fit: cover;
    }
}

@media (max-width: 991.98px) {
    .adventure-item .adventure-item-content{
        width: 245px;
    }
}