.sidebar-content{
    width: 250px;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    font-family: Heebo;
    .list-unstyled{
        text-align: center;
        display: block;
    }
    .nav-link{
        font-size: 24px;
        color: #000000
    }
    .avatar-block{
        text-align: center;
        .avatar{
            background-color: black;
            border-radius: 50%;
            height: 100px;
            width: 100px;
            background-size: cover;
            background-position: center;
            margin: 0 auto;
        }
        .avatar-label{
            color: #7F8C8D;
            font-size: 24px;
            text-align: center !important;
        }
    }
    .close-menu{
        background: url('https://api.iconify.design/ion:ios-close.svg?height=44')  0 0 no-repeat;
        width: 40px;
        height: 40px;
        align-self: flex-end;
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
    }
    .logout-group{
        color: #7F8C8D;
        font-size: 14px;
        cursor: pointer;
        .logout{
            display: inline;
        }
    }
    .support{
        margin-left: 0.25rem;
        color: #7F8C8D;
    }
    .support:hover{
        text-decoration: none;
    }
    .support:focus{
        outline: none;
    }
}