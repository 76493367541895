.gallery-component{
    margin: 0 auto;
    overflow: hidden;
    padding-bottom: 3vw;
    padding-top: 1.25rem;
}

@media (max-width: 991.98px) {
    .gallery-component {
        padding-right: 15px !important;
        .section-title{
            margin-left: 0;
        }
    }
}