.page-footer {
    background-color: transparent !important;
    padding-bottom: 3.5rem;
    width: calc(100vw - 17px);
}

footer.page-footer a {
    color: #7F8C8D;
}

.footer-component {
    width: 100%;
    margin: 0 auto;
    max-width: 900px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 2rem;
    display: flex;
    .col-8, .col-4{
        padding: 0;
    }
}

.footer-component .footer-links {
    color: #7F8C8D;
    font-family: Heebo;
    font-size: 14px;
    line-height: 26px;
    text-align: right;
    float: right;
    margin-top: 1rem;
    a{
        margin-right: 15px;
    }
    :last-child{
        margin-right: 0;
    }
}

.footer-component{
    .footer-left-container{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .link-icons{
        a{
            background-color: #959595;
            border-radius: 50%;
            vertical-align: -0.125em;
            padding: 5px;
            margin-right: 10px;
        }
        .instagram{
            content: url('https://api.iconify.design/bx-bxl-instagram.svg?color=%23fffD&height=29');
        }
        .facebook{
            content: url('https://api.iconify.design/bx-bxl-facebook.svg?color=%23fff&height=29');
        }
        .youtube{
            content: url('https://api.iconify.design/bx:bxl-youtube.svg?color=%23fff&height=29');
            margin: 0;
        }
    }
}

.footer-component .pb-3{
    display: block;
    float: right;
}

.footer-component .container-fluid{
    padding-left: 0;
}

@media (min-width: 992px) {
    .footer-component .footer-logo{
        color: #7F8C8D;
        font-family: Heebo;
        font-size: 14px;
        line-height: 26px;
        margin-bottom: 0;
    }
}

.footer-component .footer-title {
    background: url(.\..\..\..\icons\logo_footer_black.png) 0 0 no-repeat;
    width: 166px;
    background-position: center;
    height: 20px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { 
    .footer-component .footer-title {
        background-image: url(.\..\..\..\icons\logo_footer_black@2x.png);
        background-size: 166px;
    }
}

.footer-component .footer-right-container .link-icons{
    text-align: end;
}

.bottom-line-wrapper{
    max-width: 900px;
    margin: 0 auto;
    width: 100%;
}
@media (min-width: 375px) {
    .footer-links a{
        display: block;
        text-align: center;
    }
}