.images-block{
    padding-right: 15px;
    padding-left: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 160px 160px;
    column-gap: 30px;
    row-gap: 30px;
    .img-link{
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        border-radius: 4px;
    } 
    :nth-child(1){
        grid-column: 1/3;
        grid-row: 1/3;
    }
    @media (max-width: 767.98px) {
        grid-template-rows: 86px 86px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        column-gap: 15px;
        row-gap: 15px;
        :nth-child(1){
            grid-column: 1/3;
            grid-row: 1/3;
        }
        :nth-child(2){
            grid-column: 3/5;
            grid-row: 1/2;
        }
        .images-column{
            padding-left: 0;
            padding-right: 15px;
        }
        .image4{
            padding: 0;
        }
        .image4 .img-link{
            background-color: #8FC0C3;
            display: table;
            text-align: center;
        }
        .image4 .img-link span{
            display: table-cell;
            vertical-align: middle;
            color: #FFFFFF;
            font-family: Heebo;	
            font-size: 20px;
        }
    }
    @media (max-width: 991.98px) {
        padding-right: 0;
        padding-left: 0;
        margin-right: 0;
        margin-left: 0;
    }
    @media (min-width: 768px) { 
        .image4{
            display: none;
        }
    }
}