.mobile-footer{
  font-family: Heebo;
  color: #7F8C8D;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 100px;
  a{
    color: #7F8C8D;
  }
  .footer-logo{
    font-size: 14px;
    letter-spacing: 0;
    line-height: 26px;
  }
  .footer-social{
    .facebook{
      padding-right: 20px;
    }
  }
  .footer-links{
    font-size: 14px;
    letter-spacing: 0;
    line-height: 26px;
    text-align: right;
    padding: 20px 0;
  }
  .footer-title {
    background: url(.\..\..\..\icons\logo_footer_black.png) 0 0 no-repeat;
    width: 166px;
    background-position: center;
    height: 20px;
    padding: 40px 0;
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { 
      background-image: url(.\..\..\..\icons\logo_footer_black@2x.png);
      background-size: 166px;
    }
  }
}