.adventure-menu{
    background: url(.\..\..\..\icons\icon_menu.png) 0 no-repeat;
    background-position: center;
    cursor: pointer;
    padding: 0.5rem 1rem;
    z-index: 3;
    width: 30px;
    height: 30px;
    position: fixed;
    margin-top: 0.25rem;
}

.sidebar{
    z-index: 5 !important;
}

.sidebar-overlay{
    z-index: 4 !important;
}

.menu-btn-wrapper{
    width: calc(100vw - 17px);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-top: 73px;
    margin-left: -3px;
    @media (max-width: 991.98px){
        margin-top: 47px;
    }
    @media (max-width: 567.98px) {
        margin-left: 0;
    }
}
.menu-btn{
    max-width: 930px;
    padding-left: 30px;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

@media (max-width: 991.98px) {
    .menu-btn{
        padding-left: 15px;
    }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { 
    .adventure-menu{
        background-image: url(.\..\..\..\icons\icon_menu@2x.png);
        background-size: 30px;
    }
}

.menu-wrapper{
    background-color: #FFFFFF;
    width: 250px !important;
}

.bm-overlay, .bm-menu-wrap{
    top: 0;
}

.bm-burger-button{
    pointer-events: none;
}

.bm-burger-bars{
    display: none;
}