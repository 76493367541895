.latest-stories-container{
  background-color: #ECF0F1;
  padding-bottom: 56px;
  padding-top: 17px;
  .title-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 15px;
    .title-wrapper{
      color: #000;
      padding: 0;
    }
    .view-all{
      margin-bottom: 0.5rem;
      color: #7F8C8D;
      font-size: 14px;
      padding: 8px 0;
    }
  }
  @media (max-width: 767.98px) {
    .section-title{
        margin-left: 0;
    }
  }
  .latest-stories{
    max-width: 900px;
    margin: 0 auto;
    padding: 0 0 0 15px;
    position: relative;
  }
  .swiper-slide:last-child{
    margin-right: 15px;
  }
  .swiper-slide{
    width: 270px;
    @media (max-width: 767.98px) {
      width: 245px;
    }
  }
  .swiper-but-prev:before{
    content: url(.\..\..\..\icons\list_arrow_left.png);
    opacity: 1;
    vertical-align: -0.062em;
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      content: "";
    }
  }
  .swiper-but-next:before{
      content: url(.\..\..\..\icons\list_arrow_right.png);
      opacity: 1;
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        content: "";
    }
  }
  .swiper-but-prev, .swiper-but-next{
    width: 35px;
    height: 35px;
    cursor: pointer;
    top: 160px;
    position: absolute;
    outline: none;
    border: none;
    padding: 0;
    background: none;
    z-index: 2;
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      transform: scale(.5);
      width: 80px;
      height: 80px;
    }
  }
  @media only screen and (max-device-width: 991.98px) {
    .swiper-but-next, .swiper-but-prev{
        display: none;
    }
  }
  .swiper-but-prev{
    left: 15px;
    @media (max-width: 991.98px) {
        left: 0;
    }
    @media (max-width: 767.98px) {
        left: -3px;
    }
  }
  .swiper-but-next{
      right: 9px;
      @media (max-width: 991.98px) {
          right: 11px;
      }
      @media (max-width: 767.98px) {
          right: -9px;
      }
  }
}
