.tall-card-wrap{
    border-radius: 4px;
    position: relative;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)) no-repeat center center / contain;
    .tall-card{
        z-index: -1;
        position: relative;
        border-radius: 4px;
        display: block;
        line-height: 90px;
    }
    .tall-card-content{
        position: absolute;
        margin-left: 12px;
        color: #FFFFFF;	
        font-family: Heebo;	
        font-size: 12px;	
        margin-bottom: 10px;
        bottom: 0;
        .category-name{	
            font-weight: bold;	
            font-size: 14px;
        }
    }
    .price-rectangle{
        position: absolute;
        top: 0;
        margin-top: 10px;
        margin-left: 10px;
        align-self: flex-start;
    }
}

@media (max-width: 767.98px) {
    .tall-card-wrap{
        .tall-card{
            .price-rectangle{
                position: relative;
            }
        }
    }
    .tall-card-wrap{
        .tall-card:first-child{
            margin-left: 0;
        }
    }
}