.post-card{
  width: 270px;
  font-family: Heebo;
  border-radius: 4px;
  @media (max-width: 767.98px) {
    width: 245px;
  }
  .card-image-wrapper{
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: bold;
    padding: 0;
    position: relative;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%);
    .card-image{
      height: 300px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      z-index: -1;
      position: relative;
      line-height: 130px;
    }
    .card-tag{
      margin-top: 13px;
      position: absolute;
    }
    .card-tag span{
      border-radius: 4px;
      background-color: #8FC0C3;
      color: #FFFFFF;
      font-size: 11px;
      font-weight: bold;
      padding: 2px 10px;
      line-height: 17px;
      text-transform: uppercase;
      margin-left: 13px;
    }
    .card-title{
      margin-left: 13px;
      margin-right: 37px;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 23px;
      position: absolute;
      bottom: 0;
    }
  }
  .card-footer{
    display: flex;
    background-color: #FFFFFF;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.1);
    padding: 7px 11px;
    .story-author{
      width: 45px;
      height: 45px;
      border-radius: 50%;
      background-size: cover;
    }
    .author-info{
      width: calc(100% - 45px);
      padding: 0 8px;
      padding-top: 5px;
      .author-name{
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 20px;
      }
      .bottom-info{
        display: flex;
        justify-content: space-between;
        .date, .social{
          color: #7F8C8D;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 15px;
        }
        .social{
          display: flex;
          .messages{
            display: flex;
            padding-right: 7px;
            .icon{
              width: 13px;
              height: 13px;
              margin-right: 5px;
              background: url('https://api.iconify.design/ls:comment.svg?color=%23BCC6C7') no-repeat center center / contain;
            }
          }
          .likes{
            display: flex;
            .icon{
              width: 13px;
              height: 13px;
              margin-right: 5px;
              background: url('https://api.iconify.design/topcoat:like.svg?color=%23BCC6C7') no-repeat center center / contain;
            }
          }
        }
      }
    }
  }
}