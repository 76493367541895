.header{
    height: 500px;
    padding-top: 0 !important;

}

@media (max-width: 767.98px) {
    .header,  .header img{
        height: 350px;
    }
}