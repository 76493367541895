.header-navigation {
    padding-top: 0 !important;
    transform: scaleY(-1);
    width: 100%;
    z-index: 3;
    height: 100px;
    position: fixed;
    @media (max-width: 767.98px){
        height: 75px;
    }
    @media screen and (orientation:landscape) and (max-width: 991.98px){
        height: 75px;
    }
    .header-wrapper{
        width: calc(100vw - 17px);
        @media (max-width: 767.98px){
            width: 100%;
            max-width: 100vw;
        }
        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
            width: 100%;
            max-width: 100vw;
        }
    }
    .nav-link{
        .notifications-icon{
            color: #FFFFFF;
            font-size: 30px;
        }
    }
    .container{
        display: flex;
        max-width: 900px;
        transform: scaleY(-1);
        height: 3rem;
        .notifications, .notifications-mobile{
            padding: 0;
            display: flex;
            align-self: center;
            .unread-counts{
                height: 18px;
                width: 18px;
                background-color: #E74C3C;
                border-radius: 50%;
                color: #FFFFFF;
                font-size: 10px;
                font-weight: bold;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                right: 12px;
            }
        }
        .notifications-mobile{
            position: absolute;
            right: 12px;
            display: flex;
            .unread-counts{
                right: -3px;
            }
        }
    }
    .adventure{
        margin: 0 auto;
        span{
            position: absolute;
            opacity: 0;
        }
    }
    .adventure-mobile{
        position: absolute;
        margin-right: auto;
        margin-left: auto;
        right: 0;
        left: 0;
        width: 198px;
        span{
            position: absolute;
            opacity: 0;
        }
        .adventure-logo{
            height: 30px;
            width: 100%;
        }
    }
    .back-button{
        background: url('https://api.iconify.design/ic:twotone-arrow-back.svg') no-repeat center center / contain;
        border: 0;
        padding: 0;
        width: 31px;
        position: absolute;
        height: 31px;
        display: flex;
        align-self: center;
        margin-left: -6px;
    }
    .adventure-search-link{
        display: none;
    }
    .adventure-logo {
        background: url(.\..\..\..\icons\logo_footer_black.png) 0 no-repeat;
        width: 166px;
        height: 100%;
    } 
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { 
        .adventure-logo {
            background-image: url(..\..\..\icons\logo_footer_black@2x.png);
            background-size: contain !important;
        }
        .back-button {
            background: url(https://api.iconify.design/ic:baseline-close.svg) no-repeat center center / contain;
        }
    }
}