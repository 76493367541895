.explore-component{
    position: relative;
    z-index: 2;
    background-color: #FFFFFF;
    .header{
      .header-content{
        max-width: 900px;
        .price-rectangle{
          display: none;
        }
        .header-title, .header-guide, .price{
          margin-left: 0;
          white-space: pre-wrap;
          max-width: 750px;
        }
      }
      .header-carousel .row{
        max-width: 870px;
      }
    }
    .header-navigation {
        .adventure-search-link {
            background: url(.\..\..\icons\icon_search.png) 0 no-repeat;
            display: flex;
            visibility: visible;
            z-index: 3;
            position: absolute;
            right: 15px;
            height: 32px;
            align-self: center;
            .nav-link{
                padding: 0.5rem 0.75rem;
            }
        }
        .notifications{
          display: flex;
        }
        .back-button{
            visibility: hidden;
        }
    }
    .header-guide{
        pointer-events: none;
    }
    .images-block{
        padding-right: 5px;
    }
    .landing-page{
        width: 100%;
        .top-partners{
            margin-top: 0;
        }
        .bucket-container{
          background-color: #ECF0F1;
          .nearby-component{
            background-color: #ECF0F1;
            padding-bottom: 32px;
          }
        }
        .tall-adventures{
          padding-top: 1.25rem;
          .section-title{
            margin: 0;
            padding-bottom: 12px;
          }
          .tall-adventures-section{
            .swiper-wrapper{
              padding: 0;
            }
            .swiper-but-prev{
              left: -3px;
              @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
                background-image: url(.\..\..\icons\list_arrow_left@2x.png);
                left: -22px;
              }
            }
            .swiper-but-next{
              right: 1px;
              @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
                background-image: url(.\..\..\icons\list_arrow_right@2x.png);
                right: -22px;
              }
            }
          }
        }
        .gallery-component{
          .section-title{
            margin: 0
            padding-bottom .5rem;
          }
          .images-block{
            padding: 0;
          }
        }
        .latest-stories{
          max-width: 900px;
          padding: 0 0 0 15px;
          .swiper-but-prev{
            left: -3px;
            @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
              background-image: url(.\..\..\icons\list_arrow_left@2x.png);
              left: -22px;
            }
            
          }
          .swiper-but-next{
            right: 1px;
            @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
              background-image: url(.\..\..\icons\list_arrow_right@2x.png);
              right: -22px;
            }
          }
        }
      }
}