.gallery-swiper{
    height: 100%;
    .bottom-line{
        height: 1px;
        max-width: 870px;
        opacity: 1;
        background-color: #1A1A1A;
        margin: 0 auto;
    }
    .header-navigation{
        position: relative;
        .back-button{
            background: url(.\..\..\..\icons\icon_back.png) center no-repeat;
            width: 30px;
        }
    }
    .swiper-container{
        margin-top: 47px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .swiper-slide {
        background-size: cover;
        background-position: center;
    }
    h3{
        margin-left: auto;
        color: #FFFFFF;
        font-family: Heebo;	
        font-size: 16px;	
        line-height: 36px;
    }
}

@media (min-width: 992px) {
    .gallery-swiper {
        .swiper-container{
            height: 450px;
        }
        .swiper-slide{
            width: 50% !important;
            max-width: 960px;
            border-radius: 4px;
        }
        .arrows-row{
            display: flex;
            flex-wrap: wrap;
            margin-right: -15px;
            margin-left: -15px;
            max-width: 960px;
            margin: 0 auto;
            margin-top: 38px;
            height: 36px;
            padding-bottom: 73px;
            width: 50%;
        }
        .carousel-control-prev, .carousel-control-next {
            width: 35px;
            height: 35px;
            cursor: pointer;
            margin-top: auto;
            margin-bottom: 50px;
            position: relative;
            background-color: transparent;
            opacity: 1;
            border: none;
        }
        .carousel-control-prev{
            background-image: url(.\..\..\..\icons\slider_arrow_left.png);
        }
        .carousel-control-next{
            background-image: url(.\..\..\..\icons\slider_arrow_right.png);
        }
        h3{
            margin-right: auto;
        }
    }

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { 
        .gallery-swiper {
            .carousel-control-prev {
                background: url(.\..\..\..\icons\slider_arrow_left@2x.png) 0 0 no-repeat;
                background-size: 35px;
            }
            .carousel-control-next {
                background: url(.\..\..\..\icons\slider_arrow_right@2x.png) 0 0 no-repeat;
                background-size: 35px;
            }
        }
    }
}

@media (max-width: 767.98px) {
    .gallery-swiper .swiper-container{
        height: 250px;
        margin-top: auto;
        position: absolute;
        margin-bottom: auto;
        top: 0;
        bottom: 0;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .gallery-swiper .swiper-container{
        height: 350px;
        margin-top: auto;
        position: absolute;
        margin-bottom: auto;
        top: 0;
        bottom: 0;
    }
}

@media (max-width: 991.98px) { 
    .gallery-swiper{
        .carousel-control-prev, .carousel-control-next {
            width: 35px;
            height: 35px;
            cursor: pointer;
            margin-top: auto;
            margin-bottom: 50px;
            position: relative;
            background-color: transparent;
            opacity: 1;
            border: none;
        }
        .carousel-control-prev:before{
            content: url('https://api.iconify.design/dashicons:arrow-left-alt2.svg?color=%23FFFFFF&height=35');
            vertical-align: -0.125em;
            width: 35px;
            height: 35px;
        }
        .carousel-control-next:before{
            content: url('https://api.iconify.design/dashicons:arrow-right-alt2.svg?color=%23FFFFFF&height=35');
            vertical-align: -0.125em;
            width: 35px;
            height: 35px;
        }
        .arrows-row{
            position: absolute;
            width: 60%;
            display: flex;
            height: 36px;
            bottom: 20px;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
        }
        .carousel-control-next{
            margin-left: auto;
        }
        .page-footer{
            display: none;
        }
        .header-navigation{
            .container{
                max-width: 930px;
                padding-left: 15px;
            }
            .adventure-logo {
                display: none;
            }
            .back-button{
                background-image: url('https://api.iconify.design/ion:ios-close.svg?color=%23FFFFFF&height=30');
                background-size: 40px;
            }
        }
    }
}

.gallery-swiper .footer-component .footer-title {
    background: url(.\..\..\..\icons\logo_footer_white.png) 0 0 no-repeat;
    width: 166px;
    background-position: center;
    height: 20px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { 
    .gallery-swiper .footer-component .footer-title {
        background-image: url(.\..\..\..\icons\logo_footer_white@2x.png);
        background-size: 166px;
    }
}