.nearby-component{
  margin: 0 auto;
  background-color: #ffffff;
  overflow: hidden;
  padding-top:1.25rem;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 900px;
  @media (max-width: 767.98px) {
    .swiper-slide:last-child{
        margin-right: 15px;
    }
  }
  .title-wrapper{
    color: #000000;
    padding: 0;
  }
  .section-row{
    display: flex;
    justify-content: space-between;
    align-items: center; 
    .section-title{
        margin-top: 0 !important;
    }
    .view-all{
        margin-bottom: 0.5rem;
        color: #7F8C8D;
        font-size: 14px;
        padding-right: 0;
    }
    @media only screen and (max-device-width: 767.98px) {
        padding-right: 15px;
    }
  }
  .list-activity{
      width: 100%;
      display: flex;
  }
  .img-fluid{
      border-radius: 4px;
      height: 146px;	
  }
  .swiper-but-prev:before{
      content: url(.\..\..\..\icons\list_arrow_left.png);
      opacity: 1;
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        content: "";
      }
  }
  .swiper-but-next:before{
      content: url(.\..\..\..\icons\list_arrow_right.png);
      opacity: 1;
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        content: "";
      }
  }
  .swiper-but-prev, .swiper-but-next{
      width: 35px;
      height: 35px;
      cursor: pointer;
      top: 120px;
      position: absolute;
      outline: none;
      border: none;
      padding: 0;
      background: none;
      z-index: 2;
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        transform: scale(.5);
        top: 100px;
      }
  }
  a{
      color: transparent;
  }
  .no-buttons-swiper{
      width: 100%;
  }
  .swiper-but-prev{
    left: -3px;
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url(.\..\..\..\icons\list_arrow_left@2x.png);
        width: 80px;
        height: 80px;
        left: -22px;
    }
  }
  .swiper-but-next{
    right: 1px;
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url(.\..\..\..\icons\list_arrow_right@2x.png);
        width: 81px;
        height: 77px;
        right: -23px;
    }
  }
}

@media (max-width: 991.98px) {
  .nearby-component {
      .swiper-slide{
          width: 245px;
      }
      .section-title{
          margin-left: 0;
          padding-left: 0;
      }
  }
}

@media only screen and (max-device-width: 991.98px) {
  .nearby-component {
      .swiper-but-next, .swiper-but-prev{
          display: none;
      }
  }
}

@media (max-width: 767.98px) {
  .nearby-component {
      .list-activity{
          margin-bottom: 0;
          padding-left: 0;
      }
      .swiper-container{
          margin-right: 0;
      }
  }
}

@media (min-width: 992px) {
  .nearby-component {
      .swiper-slide{
          width: 270px;
      }
  }
}