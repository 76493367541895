.search-bar{
  max-width: 900px;
  margin: 0 auto;
  font-family: Heebo;
  position: relative;
  margin-top: -27px;
  z-index: 1;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 767.98px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  .search-panel{
    height: 56px;
    border: 1px solid #DFDFDF;
    border-radius: 28px;
    box-shadow: 0 5px 5px 0 rgba(0,0,0,0.1);
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    padding: 0.5rem 1.5rem;
    @media only screen and (max-device-width: 767.98px) {
      padding: 0.5rem 1rem;
    }
    .search-icon{
      color: #7F8C8D;
    }
    .search-panel-text{
      color: #7F8C8D;
      font-size: 13px;
      padding-left: 9px;
      margin: 0;
      font-weight: 400;
    }
  }
  .filters-row{
    display: flex;
    overflow: auto;
    justify-content: center;
    .filter-link{
      display: flex;
      align-items: center;
      padding: 1.125rem 2.5rem;
      .material-icons{
        color: #7F8C8D;
      }
      .filter-link-text{
        color: #000000;
        font-size: 16px;
        font-weight: bold;
        padding-left: 10px;
      }
    }
    @media only screen and (max-device-width: 767.98px) {
      .filter-link{
        flex-direction: column;
        padding-bottom: 0;
        padding-left: 11px;
        padding-right: 11px;
        .filter-link-text{
          padding-left: 0;
          padding-top: 8px;
          color: #7F8C8D;
          font-size: 12px;
        }
      }
    }
  }
}