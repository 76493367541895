.tall-adventures{
    overflow: hidden;
    padding-top: 1.25rem;
    position: relative;
    .title-wrapper{
        color: #000000;
        padding: 0;
    }
    .section-title{
        margin-left: 15px;
    }
    @media (max-width: 767.98px) {
        .swiper-slide:last-child{
            margin-right: 15px;
        }
    }
    .swiper-slide{
        width: 170px;
        @media (max-width: 767.98px){
            width: 153px;
        }
    }
    .swiper-but-prev:before{
        content: url(.\..\..\..\icons\list_arrow_left.png);
        opacity: 1;
        vertical-align: -0.062em;
        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            content: "";
        }
    }
    .swiper-but-next:before{
        content: url(.\..\..\..\icons\list_arrow_right.png);
        opacity: 1;
        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            content: "";
        }
    }
    @media only screen and (max-device-width: 991.98px) {
        .swiper-but-next, .swiper-but-prev{
            display: none;
        }
    }
    .swiper-but-prev, .swiper-but-next{
        width: 35px;
        height: 35px;
        cursor: pointer;
        top: 145px;
        position: absolute;
        outline: none;
        border: none;
        padding: 0;
        background: none;
        z-index: 2;
        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            transform: scale(.5);
            width: 80px;
            height: 80px;
            top: 110px;
        }
    }
    .swiper-but-prev{
        left: 15px;
        @media (max-width: 991.98px) {
            left: 0;
        }
        @media (max-width: 767.98px) {
            left: -3px;
        }
    }
    .swiper-but-next{
        right: 10px;
        @media (max-width: 767.98px) {
            right: -9px;
        }
    }
}

@media (max-width: 991.98px) {
    .tall-adventures{
        .section-title{
            margin-left: 0;
        }
    }
}
