.header-carousel {
    height: 500px;
    .header-content{
        position: absolute;
        max-width: 930px;
        bottom: 10%;
        left: 0;
        right: 0;
    }
    .carousel-control-prev, .carousel-control-next{
        background-color: transparent;
        opacity: 1;
        border: none;
    }
    .row{
        position: relative;
        bottom: 110px;
    }
    .slider-img-wrapper{
        height: 100%;
        /*@media (min-width: 767.98px){
            background: linear-gradient(to right, rgba(0,0,0,1) 0%,rgba(0,0,0,0.3) 35%,rgba(0,0,0,0.3) 65%,rgba(0,0,0,1) 100%)
        };*/
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
        position: relative;
    }
    .slider-img{
        object-fit: cover;
        position: relative;
        z-index: -1;
        text-align: center;
        display: block;
        line-height: 500px;
        @media (max-width: 767.98px){
            line-height: 350px;
        };
    }
    .carousel-control-prev{
        background-image: url(.\..\..\..\icons\slider_arrow_left.png);
        width: 35px;
        height: 35px;
    }
    .carousel-control-next{
        background-image: url(.\..\..\..\icons\slider_arrow_right.png);
        width: 35px;
        height: 35px;
    }
    .swiper-container{
        height: 100%;
    }
}

@media (max-width: 767.98px) {
    .header-carousel {
        height: 350px;
        .header-content {
            padding-left: 15px;
        }
    }
}

@media only screen and (min-device-width: 992px) {
    .header-carousel {
        .carousel-control-prev, .carousel-control-next{
            margin-left: 15px;
            position: relative;
        }
        .row{
            max-width: 880px;
            margin: 0 auto;
            justify-content: flex-end;
        }
    }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { 
    .header-carousel {
        .carousel-control-prev {
            background: url(.\..\..\..\icons\slider_arrow_left@2x.png) 0 0 no-repeat;
            background-size: 35px;
        }
        .carousel-control-next {
            background: url(.\..\..\..\icons\slider_arrow_right@2x.png) 0 0 no-repeat;
            background-size: 35px;
        }
    }
}

@media only screen and (max-device-width: 991.98px) {
    .header-carousel {
        .carousel-control-next, .carousel-control-prev{
            display: none;
        }
        .swiper-slide{
            width: 90% !important;
        }
    }
}