.gallery-list{
    height: 100%;
    background-color: #FFFFFF;
    overflow: auto;
    .gallery-list-item{
        height: 200px;
        margin-bottom: 15px;
        border-radius: 4px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer;
    }
    .section-title{
        margin-left: 0;
    }

    > .container{
        margin-top: 3vw;
        max-width: 930px;
    }
}