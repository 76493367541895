.top-partners{
    padding-top: 1.25rem;
    max-width: 900px;
    padding-left: 15px;
    padding-right: 15px;
    .section-row{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title-wrapper{
            color: #000000;
        }
        .view-all{
            margin-bottom: 0.5rem;
            color: #7F8C8D;
            font-size: 14px;
        }
        @media only screen and (max-device-width: 767.98px) {
            padding-right: 15px;
        }
    }
    .nav-link{
        padding: 0;
    }
    @media (max-width: 767.98px) {
        .swiper-slide:last-child{
            margin-right: 15px;
        }
    }
    .swiper-slide{
        height: 45px;
        width: auto;
        @media only screen and (max-device-width: 767.98px) {
            height: 30px;
        }
    }
    img{
        height: 100%;
    }
    margin: 0 auto;
    background-color: #FFFFFF;
    overflow: hidden;
    margin-top: 4vh;
    .nav-link.partner{
        background-color: rgba(0,0,0, 0.1);
        padding: 0;
        width: 100%;
        height: 100%;
    }
    .partners-list{
        display: flex;
        .swiper-container{
            width: 100%;
        }
    }
    @media (min-width: 992px) {
        .partners-list-empty{
            display: flex;
            padding-bottom: 1vw;
        }
    }
    .partners-list-empty{
        padding-top: 1rem;
        .swiper-container{
            margin-right: 0;
            width: 100%;
        }
    }
    .swiper-but-prev:before{
        content: url(.\..\..\..\icons\list_arrow_left.png);
        opacity: 1;
        vertical-align: -0.062em;
        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            content: "";
        }
    }
    .swiper-but-next:before{
        content: url(.\..\..\..\icons\list_arrow_right.png);
        opacity: 1;
        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            content: "";
        }
    }
    @media only screen and (max-device-width: 991.98px) {
        .swiper-but-next, .swiper-but-prev{
            display: none;
        }
    }
    .swiper-but-prev, .swiper-but-next{
        width: 35px;
        height: 35px;
        cursor: pointer;
        top: 66px;
        position: absolute;
        outline: none;
        border: none;
        padding: 0;
        background: none;
        z-index: 2;
        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            transform: scale(.5);
            width: 80px;
            height: 80px;
            top: 45px;
        }
    }
    .swiper-but-prev{
        left: -3px;
        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            background-image: url(.\..\..\..\icons\list_arrow_left@2x.png);
            left: -22px;
        }
      }
    .swiper-but-next{
        right: 1px;
        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            background-image: url(.\..\..\..\icons\list_arrow_right@2x.png);
            right: -22px;
        }
    }
}