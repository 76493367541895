.guides-sub-title{
    height: 18px;	
    color: #7F8C8D;	
    font-family: Heebo;	
    font-size: 12px;	
    line-height: 18px;	
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0;
}

.guide-item{
    border-radius: 50%;
    background-size: cover;
    max-width: 100px;
    height: 100px;
    width: 100%;
    @media (max-width: 567.98px) {
        height: 60px;
    }
    @media (min-width: 568px) and (max-width: 767.98px) {
        height: 75px;
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
        height: 90px;
    }
}