.header-content{
  .header-rating{
      display: inline-flex;
  }
  .price-rectangle{
      padding: 1.5px;
      padding-left: 10px;
      padding-right: 10px;
      margin-left: 0;
  }
  .price{
      padding-bottom: 10px;
  }
  .sold-out-component{
      margin-left: 0;
      padding-bottom: 10px;
  }
  .header-title{
      color: #FFFFFF;	
      font-family: Heebo;	
      font-size: 35px;	
      line-height: 38px;
      margin-bottom: 0;
      margin-top: 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }
  .header-guide{
      color: #8FC0C3;	
      font-family: Heebo;	
      line-height: 21px;
      margin-right: 15px;
      display: inline-flex;
  }
  .header-title{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }
}

@media (min-width: 992px) {
  .header-content {
      .header-title, .header-guide, .price, .sold-out-component{
          margin-left: 20px;
      }
  }
}

@media (max-width: 991.98px) {
  .header-content {
      .header-title, .header-guide, .price, .sold-out-component{
          margin-left: 5px;
      }
  }
}


@media (max-width: 600px) {
  .header-content {
      .header-title {
          font-size: 24px;
          white-space: pre-wrap;
          line-height: 27px;
      }
  }
}